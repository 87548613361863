<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Usuarios Panel
            <CButton
              color="primary"
              size="sm"
              class="m-1"
              @click="updateUser(false, $event)"
            >
              Agregar
            </CButton>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              v-show="!loading"
              hover
              striped
              :items="items"
              :fields="fields"
              :items-per-page="perPage"
              :pagination="pagination"
              index-column
              clickable-rows
              :activePage.sync="page"
            >
              <template #telefono="data">
                <td>{{data.item.info.telefono}}</td>
              </template>
              <template #direccion="data">
                <td>{{data.item.info.direccion}}</td>
              </template>
              <template #sponsor="data">
                <td>
                  <CButton
                    v-c-tooltip="data?.item?.sponsor ? 'Editar patrocinador asociado' : 'Asociar patrocinador'"
                    color="warning"
                    size="sm"
                    class="m-1"
                    @click="modalUpdateSponsor(data, $event)"
                  >
                    <font-awesome-icon v-show="data?.item?.sponsor" icon="edit" />
                    <font-awesome-icon v-show="!data?.item?.sponsor" icon="plus" />
                  </CButton>
                  {{data?.item?.sponsor?.name}}
                </td>
              </template>
              <template #cuadrante="data">
                <td>{{getCuadranteName(data.item.info.cuadrante_id)}}</td>
              </template>
              <template #actions="data">
                <div>
                  <CButton
                    color="warning"
                    size="sm"
                    class="m-1"
                    @click="updateUser(data.item, $event)"
                    v-if="checkPermissions(['edit_user_admin'])"
                  >
                    <font-awesome-icon icon="user-edit" />
                  </CButton>
                  <CButton
                    color="danger"
                    size="sm"
                    class="m-1"
                    @click="deleteUser(data.item.id, $event)"
                    v-if="checkPermissions(['edit_user_admin'])"
                  >
                    <font-awesome-icon icon="trash-alt" />
                  </CButton>
                </div>
              </template>
            </CDataTable>
            <div 
              v-if="loading"
              class="d-flex flex-column justify-content-center align-items-center" style="min-height: 300px;">
              <CSpinner
                style="width:4rem;height:4rem;"
                color="danger"
              />
              <p class="text-secondary my-2">Cargando datos ...</p>
            </div>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
    <EditAdminUser :showModal="showModal" :user="updatingUser" :cuadrantes="cuadrantes" :closeModal="() => closeModal()"/>

    <!--     Modal Sponsor -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Editar sponsor"
      color="primary"
      :show.sync="modalEditSponsor"
      size="sm"
      class="modal-delete"
    >
      <CCard>
        <CCardBody style="padding: 0">
          <CRow style="padding: 20px">
            <CCol col="12">
              <CSelect
                v-model="editSponsorId"
                label="Selecciona un Patrocinador"
                :options="sponsors"
              />
              <CButton color="primary" @click="updateSponsor"> Guardar </CButton>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <div slot="footer" class="w-100"></div>
    </CModal>
  </CRow>
</template>

<script>
import EditAdminUser from './EditAdminUser'
import { getAdminUsers, deleteUser } from '../../api/users'
import { getCuadrantes } from '../../api/cuadrantes'
import checkPermissions from '../../helpers/userPermissions'
import { getAdminSponsors, setUserSponsor} from "../../api/sponsor";

export default {
  name: 'AdminUsers',
  components: {EditAdminUser},
  data: () => {
    return {
      items: [],
      fields: [
        { key: 'id', label: '#' },
        { key: 'name', label: 'Nombre' },
        { key: 'email' },
        { key: 'telefono', label: 'Teléfono' },
        { key: 'sponsor', label: 'Patrocinador' },
        { key: 'direccion', label: 'Dirección' },
        { key: 'cuadrante', label: 'Cuadrante' },
        { key: 'created_at', label: 'Creado' },
        { key: 'actions', label: 'Acciones' }
      ],
      perPage: 20,
      page: 1,
      pagination: {
        align: 'center',
        doubleArrows: false,
        previousButtonHtml: 'prev',
        nextButtonHtml: 'next',
        pages: 9
      },
      updatingUser: null,
      showModal: false,
      loading: true,
      cuadrantes: null,
      modalEditSponsor: false,
      sponsors: [],
      editSponsorId: null,
      toasts: [],
    }
  },
  mounted(){
    this.getUsers()
    this.getCuadrantes()
    this.getSponsors();
  },
  methods: {
    checkPermissions,
    goToFicha(userID){      
      this.$router.push({ name: 'FichaPaciente', params: {id: userID} })
    },
    getCuadranteName(id){
      if(this.cuadrantes){
        const found = this.cuadrantes.find(function(item){
          return id === item.id
        })
        return found.nombre
      }
    },
    closeModal(){
      this.showModal = false
      this.updatingUser = null
      this.getUsers()
    },
    updateUser(user, e){
      e.stopPropagation()
      this.updatingUser = user
      this.showModal = true
    },
    deleteUser(id, e){
      e.stopPropagation()
      const data = {id}
      const self = this
      deleteUser(data)
        .then(function(resp){
          console.log(resp)
          self.getUsers()
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getCuadrantes(){
      const data = {
        page: 1,
        limit: 9999999
      }
      const self = this
      getCuadrantes(data)
        .then(function(resp){
          self.cuadrantes = resp.data
        })
        .catch(function(error){
          console.log(error)
        })
    },
    getUsers(){
      const data = {
        limit: this.perPage,
        page: this.page
      }
      const self = this
      this.loading = true
      getAdminUsers(data)
        .then(function(resp){
          self.items = resp.data
          self.loading = false
          // resp.data.map(function(item){
          //   self.items.push(item)
          // })
          self.pagination = {
            align: 'center',
            doubleArrows: true,
            previousButtonHtml: 'prev',
            nextButtonHtml: 'next',
            pages: Math.round(resp.total / self.perPage)
          }
          console.log(self.items)
        })
        .catch(function(error){
          console.log(error)
        })
    },
        getSponsors() {
      getAdminSponsors().then((res) => {
        this.sponsors = res.sponsors.map((sponsor) => {
          return { value: sponsor.id, label: sponsor.name };
        });
        this.sponsors = [...this.sponsors, {value: null, label: ''}]
      });
    },
    modalUpdateSponsor(sponsor) {
      this.modalEditSponsor = true;
      this.currentUser = sponsor.item;
      this.editSponsorId = sponsor?.item?.sponsor?.id ? sponsor?.item?.sponsor?.id : null
    },
      updateSponsor() {
      const data = {
        sponsor_id: this.editSponsorId,
        user_id: this.currentUser.id,
      };

      setUserSponsor(data)
        .then((res) => {
          this.modalEditSponsor = false;
          this.toasts.push({
            title: "Sponsor Actualizado",
          });
          this.getUsers();
        })
        .catch((e) => {
          console.log(e);
          this.toasts.push({
            title: "Error",
          });
        });
    },
    getBadge (status) {
      return status === 'Active' ? 'success'
        : status === 'Inactive' ? 'secondary'
          : status === 'Pending' ? 'warning'
            : status === 'Banned' ? 'danger' : 'primary'
    }
  }
}
</script>
